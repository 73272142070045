// import 'react-app-polyfill/stable';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SplitFactory } from '@splitsoftware/splitio';
import JsBridge from './utils/JsBridge';
import ApiService from './services/ApiService';
import TokenService from './services/TokenService';
import HELPER from './utils/Helper';
import APP_ROUTER, { IRoute } from './services/Router';
import ClearCache from './ClearCache';
import ErrorGeneral from './components/ErrorGeneral/ErrorGeneral.comp';
import LoadingGeneral from './components/LoadingGeneral/LoadingGeneral.comp';
import AppProvider, { AppContext } from './store/AppProvider';
import './translations';
import './assets/app.scss';
import { SET_BILLER_NOTICE } from './store/AppReducers';

const { anteikuClient, setBearer } = ApiService;
interface IProps {}

const App: FC<IProps> = () => {
  const context = useContext(AppContext);
  const [appReady, setAppReady] = useState<boolean>(false);

  useLayoutEffect(() => {
    context.setLoading(true);
  }, []);

  useEffect(() => {
    /**
     * ! IMPORTANT STEP TO OPEN APP
     * 1. Check container is in skywalker + initiate jsbridge native app
     * 2. Load BE Config (Maintenance, Status and Service) (Optional if available)
     * 3. Fetch initial token for access BE service API
     * 4. Load split config save config into storage
     * 5. render router application
     */
    if ((window as any).IS_IN_SKYWALKER || (window as any).IS_IN_PLAYGROUND) {
      initNative();
      initAuthToken()
        .then(() => {
          // SUCCESS CONDITION
          initSplit(() => {
            setAppReady(true);
          });
        })
        .catch(() => {
          // FAILURE CONDITION
          setAppReady(false);
          context.setLoading(false);
          context.setError(true);
        });
    } else {
      setAppReady(false);
      context.setLoading(false);
      context.setError(true);
    }
  }, []);

  const serviceId = useMemo(
    () => window.location.pathname.substring(1).replace('services/', ''),
    [],
  );

  const billerId = useMemo(
    () => window.location.pathname.substring(1).replace('biller/', ''),
    [],
  );

  const initNative = useCallback(() => {
    document.addEventListener(
      'AlipayJSBridgeReady',
      () => {
        JsBridge.setTitle('DANA');
        JsBridge.setDefaultBack();
        JsBridge.on('resume', JsBridge.setDefaultBack);
      },
      false,
    );
  }, []);

  const initAuthToken = useCallback(async () => {
    const authParam = {
      auth_code:
        HELPER.getParamURL('authCode') || HELPER.getParamURL('auth_code'),
      dana_env: HELPER.getParamURL('env'),
      dana_creds_owner: serviceId || billerId,
      request_id: HELPER.getParamURL('requestId') || HELPER.createUUID(),
    };
    const { getToken, setToken } = TokenService;
    try {
      // SET TOKEN IF HAVE
      if (getToken()) {
        setToken(getToken());
        setBearer(getToken());
      } else if (authParam.auth_code && authParam.request_id) {
        const response = await anteikuClient.post('auth/dana', authParam);
        const { payload, status } = response.data;
        if (status === 'success') {
          // Set Token To Session
          setToken(payload.auth_token);
          // Set Token To API Service
          setBearer(payload.auth_token);
        } else {
          throw new Error('API_STATUS_FAILURE');
        }
      } else {
        throw new Error('INVALID_PARAM');
      }
    } catch (error) {
      throw new Error('TOKENIZATION_FAILURE');
    }
  }, []);

  const initSplit = useCallback((cb: () => void) => {
    const splitKey = HELPER.getUserId();
    const splitClient = SplitFactory({
      core: {
        authorizationKey: process.env.SPLIT_KEY || '',
        key: splitKey,
      },
    }).client();
    const attributes = {
      environment: process.env.ENV || 'STAGING',
      device: (window as any).IS_ANDROID ? 'Android' : 'iPhone',
      os_type: (window as any).IS_ANDROID ? 'Android' : 'iOS',
      app_name: 'DANA All Biller - BPD',
      service_id: serviceId,
    };
    splitClient.on(splitClient.Event.SDK_READY, () => {
      const splitResult: any = splitClient.getTreatmentWithConfig(
        'biller_notice_message',
        attributes,
      );
      const noticeConfig = JSON.parse(splitResult.config);
      context.setContext(noticeConfig, SET_BILLER_NOTICE);
      cb();
    });
    splitClient.on(splitClient.Event.SDK_READY_TIMED_OUT, () => {
      context.setLoading(false);
      context.setError(
        true,
        'Configuration App!',
        'The Apps, failure to get configuration from internal config.',
      );
    });
  }, []);

  const renderRouter = useMemo(
    () =>
      appReady && (
        <BrowserRouter>
          <Routes>
            {APP_ROUTER.map((item: IRoute) => (
              <Route {...item} element={<item.element />} />
            ))}
            <Route path="*" element={<p>404 Page</p>} />
          </Routes>
        </BrowserRouter>
      ),
    [appReady],
  );

  // DID MOUNT IF HAVE INITIALIZATION DATA BEFORE RENDER
  return (
    <>
      <LoadingGeneral isLoading={context.isLoading} />
      <ErrorGeneral {...context.errorState} />
      {renderRouter}
    </>
  );
};

ReactDOM.render(
  <ClearCache>
    <AppProvider>
      <App />
    </AppProvider>
  </ClearCache>,
  document.getElementById('root'),
);

import 'regenerator-runtime/runtime';
import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../store/AppProvider';
import ApiService from '../services/ApiService';
import useSession from './useSession';
import { ACCESS_TOKEN } from '../constants';
import { SET_BILLER_LIST, SET_SERVICE_DETAIL } from '../store/AppReducers';
import { IParamsCheckout } from '../interfaces/ICheckout';
const { anteikuClient: client, setBearer } = ApiService;

export interface IStateAPIAnteiku {
  loading: boolean;
  data: any;
  error: any;
}

const useApiAnteiku = () => {
  const context = useContext(AppContext);
  const [state, setState] = useState<IStateAPIAnteiku>({
    loading: true,
    data: null,
    error: null,
  });

  const [token] = useSession(ACCESS_TOKEN, '');

  useEffect(() => {
    // ? SET TOKEN FIRST IF TOKEN AVAILABLE
    if (!client.defaults.headers.common.Authorization) {
      setBearer(token);
    }
  }, []);

  const initServiceData = async (serviceId?: string) => {
    try {
      const getServiceDetail = await client.get(`services/${serviceId}`);
      const params = { page_num: 1, page_size: 500 };
      const getServiceBillers = await client.get(
        `services/${getServiceDetail.data.payload.id}/billers`,
        { params },
      );
      return Promise.resolve({
        serviceDetail: getServiceDetail.data.payload,
        billerList: getServiceBillers.data.payload,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const createCheckout = async (params: IParamsCheckout) => {
    try {
      const reqCheckout = await client.post('/checkout', params);
      const reqLabel = await axios.get(
        'https://a.m.dana.id/resource/json/dana-home/all-biller-order-detail-label.json',
      );
      // Will Delete After Wording Checking
      const ADDITIONAL_LABEL = {};
      return Promise.resolve({
        checkout: reqCheckout.data.payload,
        label: { ...reqLabel.data, ...ADDITIONAL_LABEL },
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const requestPay = async (salesOrderId: string) => {
    try {
      const result = await client.post(`orders/${salesOrderId}/pay`);
      return Promise.resolve(result.data.payload);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const requestServiceData = (serviceId?: string) => {
    initServiceData(serviceId)
      .then((data: any) => {
        // SET SERVICE TO CONTEXT
        context.setContext(data.serviceDetail, SET_SERVICE_DETAIL);
        // SET BILLER TO CONTEXT
        context.setContext(data.billerList, SET_BILLER_LIST);
        // UPDATE STATE
        setState((prevState) => ({
          ...prevState,
          data,
          loading: false,
        }));
      })
      .catch((error: any) => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          error,
        }));
      })
      .finally(() => {
        // SET SERVICE TO CONTEXT
        context.setLoading(false);
      });
  };

  const requestTNC = async (billerId: string) => {
    try {
      const result = await client.get(`billers/${billerId}`);
      return Promise.resolve(result.data.payload);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return {
    requestTNC,
    requestServiceData,
    createCheckout,
    requestPay,
    state,
  };
};

export default useApiAnteiku;

import React, { FC } from 'react';
import './404.scss';

export interface IProps {}

const NotFoundPage: FC<IProps> = () => (
  <div className="not-found-page">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <h1>404 Not Found</h1>
          <p> Opps you`re open wrong page..</p>
        </div>
      </div>
    </div>
  </div>
);

export default NotFoundPage;

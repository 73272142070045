import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router';
import './TNC.scss';

const decodeHtml = (escapedHtml: any) => {
  const unescapedHtml = unescape(escapedHtml);
  const fontFamilyNativeRegex = /font-family: Native Font;/g;

  return unescapedHtml
    .replace(fontFamilyNativeRegex, '')
    .split('px')
    .map((element, index, array) => {
      if (index < array.length - 1) {
        const word = element.split(' ');
        const fontSizeIndex = word.length - 1;

        const fontSize = (parseFloat(word[fontSizeIndex]) / 10).toString();
        word[fontSizeIndex] = `${fontSize}rem`;

        return word.join(' ');
      }
      return element;
    })
    .join('');
};

const TNC: FC = () => {
  const content = localStorage.getItem('temporary_tnc');
  const rootHTML = document.querySelector('html');
  const location = useLocation();

  useEffect(() => {
    if (rootHTML) rootHTML.style.fontSize = '27.5px';

    return () => {
      if (rootHTML) rootHTML.style.fontSize = '275px';
    };
  }, [location]);

  return (
    <div className="preview-tnc">
      <div
        className="content-tnc"
        dangerouslySetInnerHTML={{
          __html: decodeHtml(content),
        }}
      ></div>
    </div>
  );
};

export default TNC;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DEFAULT_ICON from '../../assets/icons/paper-tax-icon.svg';
import CURIOUS_FINDING from '../../assets/images/curious-finding.svg';
import ARROW_ICON from '../../assets/icons/arrow-icon.svg';
import './ListView.scss';

export interface ListViewData {
  id: string | number;
  icon?: string;
  text: string;
}

export interface IProps {
  list: ListViewData[];
  isLoading: boolean;
  onTapItem: (id: ListViewData) => void;
}

const ListView: FC<IProps> = (props) => {
  const { list, isLoading, onTapItem } = props;
  const { t: formatMessage } = useTranslation();

  const handleTapItem = (id: ListViewData) => () => {
    onTapItem(id);
  };

  if (isLoading) {
    return null;
  }

  if (list.length <= 0 && !isLoading) {
    return (
      <div className="list-view">
        <div className="list-empty">
          <img src={CURIOUS_FINDING} alt="empty" />
          <h2>{formatMessage('comp.listView.title')}</h2>
          <p>{formatMessage('comp.listView.message')}</p>
        </div>
      </div>
    );
  }
  return (
    <div className="list-view">
      {list.map((item: ListViewData) => (
        <div className="list-item" key={item.id} onClick={handleTapItem(item)}>
          <div className="icon">
            <img src={item.icon || DEFAULT_ICON} alt="List View Icon" />
          </div>
          <div className="text">{item.text}</div>
          <div className="navigation">
            <img src={ARROW_ICON} alt="Arrow Icon" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListView;

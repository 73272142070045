import React, { FC, ReactNode } from 'react';
import './StickyNote.scss';

export interface IProps {
  title?: string;
  children: ReactNode;
}

const StickyNote: FC<IProps> = (props) => {
  const { children, title } = props;
  return (
    <div className="sticky-note">
      <div className="sticky-title">{title}</div>
      <div className="sticky-content">{children}</div>
    </div>
  );
};

export default React.memo(StickyNote);

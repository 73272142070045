import lGet from 'lodash/get';
export const getLang = (): 'en' | 'id' => {
  switch (window.navigator.language.split('-')[0]) {
    case 'en':
      return 'en';
    case 'id':
      return 'id';
    default:
      return 'id';
  }
};

export const stringMatch = (str: string, find: string) => {
  const strRegExPattern = `\\b${find}\\b`;
  return str.match(new RegExp(strRegExPattern, 'g'));
};

export const ArrayStringSearch = (
  objectTarget: string,
  str: string,
  listObject: any,
) =>
  listObject.filter(
    (el: any) => el[objectTarget].toLowerCase().indexOf(str.toLowerCase()) > -1,
  );

export const createUUID = (): string => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 || 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r && 0x3) || 0x8).toString(16);
  });
  return uuid;
};

export const getParamURL = (urlKey: string) => {
  const rawUrl = window.location.search;
  const result = rawUrl
    .substring(rawUrl.indexOf('?') + 1)
    .split('&')
    .reduce(
      (memo, param) => ({
        ...memo,
        [param.split('=')[0]]: param.split('=')[1],
      }),
      {},
    );
  return lGet(result, urlKey, '');
};

export const formatStdNumber = (num: number): string =>
  num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

export const getUserId = (): string => {
  const isOnDebug = window.location.hostname === 'localhost';
  if (isOnDebug) return 'DEBUG';
  if (process.env.ENV === 'PRODUCTION') {
    const value = `; ${lGet(document, 'cookie')}`;
    const parts = value.split(`; userId=`) || [];
    if (parts.length === 2) {
      const partsPop = parts.pop();
      if (partsPop) return partsPop.split(';').shift() || '';
      return '';
    }
    return '';
  }
  return process.env.INJECTED_ID_MIXPANEL || '';
};

export const decodeHtml = (escapedHtml: string) => {
  const unescapedHtml = decodeURI(escapedHtml);
  const fontFamilyNativeRegex = /font-family: Native Font;/g;

  return unescapedHtml
    .replace(fontFamilyNativeRegex, '')
    .split('px')
    .map((element, index, array) => {
      if (index < array.length - 1) {
        const word = element.split(' ');
        const fontSizeIndex = word.length - 1;

        const fontSize = (parseFloat(word[fontSizeIndex]) / 10).toString();
        word[fontSizeIndex] = `${fontSize}rem`;

        return word.join(' ');
      }
      return element;
    })
    .join('');
};

export const trimerString = (s: string): string =>
  s.replace(/\s+/g, '').toUpperCase();

export const formatRupiah = (value: string): string => {
  let x = '';
  const r = value.split('').reverse().join('');
  for (let i = 0; i < r.length; i += 1)
    if (i % 3 === 0) x += `${r.substr(i, 3)}.`;
  return `Rp${x
    .split('', x.length - 1)
    .reverse()
    .join('')}`;
};

export default {
  getLang,
  createUUID,
  stringMatch,
  getParamURL,
  formatStdNumber,
  getUserId,
  ArrayStringSearch,
  trimerString,
  formatRupiah,
};

import React from 'react';
import Button from '../../components/Button/Button.comp';
import NO_BILLER from '../../assets/images/no-biller.png';
import SOMETHING_WRONG from '../../assets/images/general_error.png';
import BILLER_PAID from '../../assets/images/biller_paid.png';

export interface IProps {
  typeContent: string;
  onClose: () => void;
  t: any;
}

const BillerBottomSheet = ({ typeContent, onClose, t }: IProps) => {
  switch (typeContent) {
    case 'BILL_ALREADY_EXPIRED':
    case 'BILL_ALREADY_PAID':
      return (
        <div className="bottom-sheet-general">
          <img className="notifier-img" src={BILLER_PAID} alt="No Biller" />
          <h2>{t('panel.title.billerPaid')}</h2>
          <p>{t('panel.message.billerPaid')}</p>
          <Button onClick={onClose} classStyle="primary full bold">
            {t('notifier.button')}
          </Button>
        </div>
      );
    case 'BILL_PAYMENT_NOT_FOUND':
    case 'BILLER_PAYMENT_NOT_FOUND':
    case 'ACCOUNT_BANK_NOT_EXIST':
    case 'ACCOUNT_BANK_INVALID':
    case 'WITHDRAW_NAME_CHECK_FAILED':
    case 'WITHDRAW_INVALID_NAME_INFO':
    case 'FORMAT_ACCOUNT_ERROR':
    case 'ACCOUNT_BANK_CLOSED':
      return (
        <div className="bottom-sheet-general">
          <img className="notifier-img" src={NO_BILLER} alt="No Biller" />
          <h2>{t('notifier.noBill.title')}</h2>
          <p>{t('notifier.noBill.desc')}</p>
          <Button onClick={onClose} classStyle="primary full bold">
            {t('notifier.button')}
          </Button>
        </div>
      );
    case 'UNKNOWN_BILLER':
      return (
        <div className="bottom-sheet-general">
          <img className="notifier-img" src={NO_BILLER} alt="No Biller" />
          <h2>{t('notifier.noBill.title')}</h2>
          <p>Type of biller didn&apos;t Match</p>
          <Button onClick={onClose} classStyle="primary full bold">
            {t('notifier.button')}
          </Button>
        </div>
      );
    default:
      return (
        <div className="bottom-sheet-general">
          <img
            className="notifier-img"
            src={SOMETHING_WRONG}
            alt="Something Wrong"
          />
          <h2>{t('panel.title.somethingWrong')}</h2>
          <p>{t('panel.message.somethingWrong')}</p>
          <Button onClick={onClose} classStyle="primary full bold">
            {t('notifier.button')}
          </Button>
        </div>
      );
  }
};

export default BillerBottomSheet;

import React, { FC, useEffect, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import CLOSE_ICON from '../../assets/icons/close-icon.svg';
import './TextInput.scss';

export interface ValidationTextInput {
  required?: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  pattern?: any;
  validate?: boolean;
  message: string;
}

export interface IProps {
  title: string;
  name: string;
  placeholder?: string;
  icon?: string;
  formHook: UseFormReturn<FieldValues, any>;
  type: 'text' | 'number';
  validation: ValidationTextInput[];
}

const TextInput: FC<IProps> = (props) => {
  const { title, name, placeholder, icon, formHook, type, validation } = props;
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const {
    register,
    resetField,
    watch,
    formState: { errors, touchedFields },
  } = formHook;

  useEffect(() => {
    const subscription = watch((inputValue) => {
      const str = inputValue[name];
      if (str === '') {
        setIsFilled(false);
      } else {
        setIsFilled(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleClear = () => {
    resetField(name);
    setIsFilled(false);
  };

  const wrongInput = errors[name];
  const validationRegister = {};
  const validationList: { validationType: string; message: string }[] = [];
  validation.forEach((data: ValidationTextInput) => {
    const validationObj = Object.entries(data)[0];
    const message = Object.entries(data)[1][1];
    Object.assign(validationRegister, { [validationObj[0]]: validationObj[1] });
    validationList.push({ validationType: validationObj[0], message });
  });
  const isInputValidByIndex = validationList.findIndex(
    (x: any) => x.validationType === wrongInput?.type || '',
  );

  return (
    <div className={`text-input ${wrongInput ? 'error' : ''}`}>
      <div className="form-title">
        <div className="text-title">{title}</div>
        {icon && (
          <div className="icon-title">
            <img src={icon} alt="Icon Form" />
          </div>
        )}
      </div>
      <div className="form-input">
        <input
          id={name}
          placeholder={placeholder}
          type={type}
          pattern="[0-9]"
          {...register(name, validationRegister)}
        />
        <div
          className={`close ${isFilled ? 'active' : ''}`}
          onClick={handleClear}
        >
          <img src={CLOSE_ICON} alt="Seach Icon" />
        </div>
        {touchedFields && isInputValidByIndex > -1 && (
          <span className="notice-message">
            {validationList[isInputValidByIndex]?.message}
          </span>
        )}
      </div>
    </div>
  );
};

export default TextInput;

import React, {
  FC,
  ReactNode,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import CLOSE_ICON from '../../assets/icons/close-icon.svg';
import HELPER from '../../utils/Helper';
import './SelectInput.scss';

export interface SelectList {
  id: string;
  name: string;
}
export interface ValidationSelectInput {
  required?: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  pattern?: any;
  validate?: boolean;
  message: string;
}
export interface IProps {
  title: string;
  name: string;
  placeholder?: string;
  icon?: string;
  selectList: SelectList[];
  formHook: UseFormReturn<FieldValues, any>;
  validation: ValidationSelectInput[];
  onSelectItem?: (text: string) => void;
}

export interface IStateSelectInput {
  value: string;
  showList: boolean;
  listItem: SelectList[];
  preventBlur: boolean;
  isFilled: boolean;
}

const SelectInput: FC<IProps> = (props) => {
  const {
    title,
    name,
    placeholder,
    icon,
    selectList,
    formHook,
    validation,
    onSelectItem,
  } = props;

  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors, touchedFields },
  } = formHook;

  const [state, setState] = useState<IStateSelectInput>({
    value: '',
    showList: false,
    listItem: selectList,
    preventBlur: false,
    isFilled: false,
  });

  const updateState = (obj: any) => {
    setState((prevState) => ({
      ...prevState,
      ...obj,
    }));
  };

  const isMounted = useRef(false);
  const inputID = `${name}Id`;

  useEffect(() => {
    const subscription = watch((inputValue) => {
      const str = inputValue[name];
      handleOnChange(str || '');
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (isMounted.current) {
      const { value } = state;
      const val = value === '' ? undefined : value;
      updateState({ isFilled: val !== undefined });
      setValue(name, val, {
        shouldValidate: true,
      });
    } else {
      isMounted.current = true;
    }
  }, [state.value]);

  const handleOnSelect = (item: SelectList) => () => {
    updateState({
      preventBlur: true,
      value: item.name,
      showList: false,
    });
    clearErrors(name);
    setTimeout(() => {
      updateState({
        preventBlur: false,
      });
      // SET HIDDEN INPUT VALUE
      setValue(inputID, item.id);
      onSelectItem && onSelectItem(item.id);
      localStorage.setItem('biller_id', item.id.toString());
    }, 0);
  };
  const handleOnFocus = () => {
    updateState({
      showList: true,
    });
  };
  const handleOnBlur = () => {
    if (!state.preventBlur) {
      updateState({
        preventBlur: false,
        showList: false,
        value: '',
        listItem: selectList,
      });
    }
  };
  const handleOnChange = useCallback(
    (inputValue: string) => {
      updateState({
        value: inputValue,
        listItem: HELPER.ArrayStringSearch('name', inputValue, selectList),
      });
    },
    [setState],
  );

  const renderBoldLabel = (str: string, label: string): ReactNode | string => {
    const index = label.toLowerCase().indexOf(str.toLowerCase());
    if (index !== -1) {
      const { length } = str;
      const prefix = label.substring(0, index);
      const suffix = label.substring(index + length);
      const match = label.substring(index, index + length);
      return (
        <>
          {prefix}
          <b>{match}</b>
          {suffix}
        </>
      );
    }
    return label;
  };

  const handleClear = () => {
    handleOnChange('');
  };

  const wrongInput = errors[name];
  const validationRegister = {};
  const validationList: { validationType: string; message: string }[] = [];
  validation.forEach((data: ValidationSelectInput) => {
    const validationObj = Object.entries(data)[0];
    const message = Object.entries(data)[1][1];
    Object.assign(validationRegister, { [validationObj[0]]: validationObj[1] });
    validationList.push({ validationType: validationObj[0], message });
  });
  const isInputValidByIndex = validationList.findIndex(
    (x: any) => x.validationType === wrongInput?.type || '',
  );

  return (
    <div className={`select-input ${wrongInput ? 'error' : ''}`}>
      <div className="form-title">
        <div className="text-title">{title}</div>
        {icon && (
          <div className="icon-title">
            <img src={icon} alt="Icon Form" />
          </div>
        )}
      </div>
      <div className="form-input">
        <input id={inputID} type="hidden" {...register(inputID)} />
        <input
          id={name}
          placeholder={placeholder}
          onFocus={handleOnFocus}
          {...register(name, { ...validationRegister, onBlur: handleOnBlur })}
        />
        <div
          className={`close ${state.isFilled ? 'active' : ''}`}
          onClick={handleClear}
        >
          <img src={CLOSE_ICON} alt="Seach Icon" />
        </div>
        {!state.showList && touchedFields && isInputValidByIndex > -1 && (
          <span className="notice-message">
            {validationList[isInputValidByIndex]?.message}
          </span>
        )}
        {state.showList && (
          <div className="select-list">
            {state.listItem.map((item: SelectList) => (
              <div
                key={item.id}
                className="item"
                onMouseDown={handleOnSelect(item)}
              >
                {renderBoldLabel(state.value, item.name)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(SelectInput);

import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import get from 'lodash/get';
import JSBridge from '../../utils/JsBridge';
import HELPER from '../../utils/Helper';
import Button from '../Button/Button.comp';
import CROSS_ICON from '../../assets/icons/cross-icon.svg';
import ICON_BILLER from '../../assets/icons/paper-tax-icon.svg';
import './BottomSheetConfirmation.scss';
import { AppContext } from '../../store/AppProvider';
import useApiAnteiku from '../../hooks/useApiAnteiku';

export interface ListItemConfirmation {
  id: string;
  itemCss: string;
  valueCss: string;
  nameCss: string;
  name: string;
  value: string;
}

export interface IPropsBottomSheetConfirmation {
  isOpen: boolean;
  name: string;
  data: {
    icon?: string;
    title: string;
    desc: string;
    listItem: ListItemConfirmation[];
  };
  onConfirm?: () => void;
  onClose?: () => void;
  navigate: Function;
}

const handlePopTnc = (
  tnc: any,
  navigate?: Function,
  formatMessage?: Function,
) => {
  localStorage.removeItem('temporary_tnc');
  localStorage.setItem('temporary_tnc', tnc);
  navigate && navigate('/tnc');
  JSBridge.setTitle(formatMessage ? formatMessage('panel.footer.tnc') : '');
};

const getAgreementTNC = (props: any) => async () => {
  const { context, billerId, requestTNC, navigate, formatMessage } = props;

  let resolve = null;
  try {
    context.setLoading(true);
    const TNC = await requestTNC(billerId);

    if (TNC.id) {
      const { tnc } = TNC;
      resolve = get(tnc, 'latest_content_url', '');

      axios
        .get(resolve)
        .then((response) => {
          context.setLoading(false);
          handlePopTnc(
            response.data[HELPER.getLang()],
            navigate,
            formatMessage,
          );
        })
        .catch(() => {
          context.setLoading(false);
          handlePopTnc('TNC NOT AVAILABLE');
        });
    } else {
      resolve = Promise.reject(TNC);
    }
    context.setLoading(false);
  } catch (error) {
    resolve = Promise.reject(error);
    context.setLoading(false);
  }
  return resolve;
};

const renderCheckBox = (
  isAgree: boolean,
  setAgreementStatus: Function,
  context: any,
  billerId: any,
  formatMessage: Function,
  requestTNC: Function,
  navigate: Function,
) => (
  <div className="bottom-sheet-agreement">
    <input
      checked={isAgree}
      type="checkbox"
      className="bottom-sheet-agreement-checkbox"
      onChange={() => setAgreementStatus(!isAgree)}
    />
    <span className="bottom-sheet-agreement-desc">
      {formatMessage('panel.footer.agreement')}
    </span>
    <button
      type="button"
      className="bottom-sheet-agreement-link"
      onClick={getAgreementTNC({
        context,
        billerId,
        requestTNC,
        navigate,
        formatMessage,
      })}
    >
      {formatMessage('panel.footer.tnc')}
    </button>
  </div>
);

const BottomSheetConfirmation: FC<IPropsBottomSheetConfirmation> = (props) => {
  const { name, isOpen, data, onConfirm, onClose, navigate } = props;
  const { t: formatMessage } = useTranslation();
  const context = useContext(AppContext);
  const { requestTNC } = useApiAnteiku();
  const [isAgree, setAgreementStatus] = useState<boolean>(true);

  const billerId = localStorage.getItem('biller_id');

  return (
    <div className={`bottom-sheet-confirmation ${isOpen ? 'open' : ''}`}>
      <div className="background-layer" onClick={onClose}></div>
      <div className="bottom-sheet-wrapper">
        <div className="bsc-head">
          <div className="head-top">
            <div className="icon-close" onClick={onClose}>
              <img src={CROSS_ICON} alt="Cross Icon" />
            </div>
            <div className="bsc-title">{name}</div>
          </div>
          <div className="head-low">
            <div className="icon-biller">
              <img src={data.icon || ICON_BILLER} alt="biller Icon" />
            </div>
            <div className="biller-info">
              <div className="biller-title">{data.title}</div>
              <div className="biller-desc">{data.desc}</div>
            </div>
          </div>
        </div>
        <div className="bottom-sheet-content">
          <div className="bsc-list-confirm">
            {data.listItem.map((item: ListItemConfirmation) => (
              <div key={item.id} className={`item ${item.itemCss}`}>
                <div className={`item-name ${item.nameCss}`}>{item.name}</div>
                <div className={`item-value ${item.valueCss}`}>
                  {item.value}
                </div>
              </div>
            ))}
          </div>
          <div className="bsc-btn">
            {renderCheckBox(
              isAgree,
              setAgreementStatus,
              context,
              billerId,
              formatMessage,
              requestTNC,
              navigate,
            )}
            <Button
              onClick={onConfirm}
              classStyle={`${
                isAgree ? 'active' : 'inactive'
              } primary full bold uppercase`}
            >
              {formatMessage('panel.footer.btn')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BottomSheetConfirmation);

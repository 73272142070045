import React, { FC, ReactNode } from 'react';
import LOADING_SPINNER from '../../assets/images/loadingspinner.png';
import './BottomSheet.scss';

export interface IProps {
  isOpen: boolean;
  children: ReactNode;
  loadingState?: boolean;
  onSwipe?: () => void;
  onClose?: () => void;
}

const BottomSheet: FC<IProps> = (props) => {
  const { children, onSwipe, onClose, isOpen, loadingState } = props;
  const renderContent = () => {
    if (loadingState) {
      return (
        <div className="bottom-sheet-wrapper">
          <div className="bottom-sheet-content">
            <div className="loader-bottom-sheet">
              <div className="spinner">
                <img className="rotate" src={LOADING_SPINNER} alt="loading" />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="bottom-sheet-wrapper">
        <div className="drag-indicator" onClick={onSwipe}></div>
        <div className="bottom-sheet-content">{children}</div>
      </div>
    );
  };

  return (
    <div className={`bottom-sheet ${isOpen ? 'open' : ''}`}>
      <div className="background-layer" onClick={onClose}></div>
      {renderContent()}
    </div>
  );
};

export default React.memo(BottomSheet);

import React, { FC } from 'react';
import LOADING_SPINNER from '../../assets/images/loadingspinner.png';
import './LoadingGeneral.scss';

export type IProps = {
  isLoading: boolean;
};

const LoadingGeneral: FC<IProps> = ({ isLoading }) => {
  return (
    <div className={`loading-mask ${isLoading ? 'on' : ''}`}>
      <div className="spinner">
        <img className="rotate" src={LOADING_SPINNER} alt="loading" />
      </div>
    </div>
  );
};

export default React.memo(LoadingGeneral);

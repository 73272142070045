import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import lUniqBy from 'lodash/uniqBy';
import lUniqueId from 'lodash/uniqueId';
import lGet from 'lodash/get';
import HELPER from '../../utils/Helper';
import ErrorGeneral from '../../components/ErrorGeneral/ErrorGeneral.comp';
import SearchInput from '../../components/SearchInput/SearchInput.comp';
import ListView, {
  ListViewData as IListViewData,
} from '../../components/ListView/ListView.comp';
import './Services.scss';
import { BillerListView } from '../../interfaces/IResponses';
import useApiAnteiku from '../../hooks/useApiAnteiku';
import JsBridge from '../../utils/JsBridge';

export interface IProps {}
export interface IStateService {
  listBiller: IListViewData[];
  listBillerLoading: boolean;
  listBillerFiltered: IListViewData[];
  error?: any;
}

export interface ContentProps {
  handleOnClearSearch: () => void;
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnTapListItem: (id: IListViewData) => void;
  formatMessage: Function;
  state: any;
}

const renderContent = ({
  handleOnClearSearch,
  handleSearchChange,
  handleOnTapListItem,
  formatMessage,
  state,
}: ContentProps) => (
  <>
    <div className="search-service">
      <SearchInput
        onClose={handleOnClearSearch}
        onChange={handleSearchChange}
        placeholder={formatMessage('service.search.placeholder')}
      />
    </div>
    <div className="list-view-service">
      <ListView
        isLoading={state.listBillerLoading}
        list={state.listBillerFiltered}
        onTapItem={handleOnTapListItem}
      />
    </div>
  </>
);

const renderError = (error: string, formatMessage: Function) => (
  <ErrorGeneral
    isError={!!error}
    title={formatMessage('error.maintenance.title')}
    message={formatMessage('error.maintenance.desc')}
    buttonText={formatMessage('error.maintenance.button')}
  />
);

const Services: FC<IProps> = () => {
  const { serviceId } = useParams();
  const navigate = useNavigate();
  const { t: formatMessage } = useTranslation();
  const serviceApi = useApiAnteiku();
  const [state, setState] = useState<IStateService>({
    listBiller: [],
    listBillerLoading: true,
    listBillerFiltered: [],
  });

  useEffect(() => {
    serviceApi.requestServiceData(serviceId);
  }, []);

  useEffect(() => {
    const { data, error } = serviceApi.state;

    if (data !== null) {
      document.addEventListener('AlipayJSBridgeReady', () => {
        initNative(data.serviceDetail.name);
      });
      const billerList = mapBillerGroupToListView(data.billerList);
      setState((prevState) => ({
        ...prevState,
        listBiller: billerList,
        listBillerLoading: false,
        listBillerFiltered: billerList,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        error,
      }));
    }
    return () => {
      document.removeEventListener('AlipayJSBridgeReady', () => {});
    };
  }, [serviceApi.state]);

  const initNative = (serviceName: string) => {
    JsBridge.setTitle(serviceName);
  };

  const mapBillerGroupToListView = (data: BillerListView[]) => {
    const remapData = data.map(
      (item: BillerListView) =>
        ({
          id: lGet(item, 'biller_group_id', lUniqueId()),
          icon: item.icon_abs_url,
          text: lGet(item, 'biller_group_name', 'Unknown'),
        } as IListViewData),
    );
    return lUniqBy(remapData, 'id');
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      listBillerFiltered: HELPER.ArrayStringSearch(
        'text',
        e.target.value,
        prevState.listBiller,
      ),
    }));
  };

  const handleOnClearSearch = () => {
    setState((that) => ({
      ...that,
      listBillerFiltered: that.listBiller,
    }));
  };

  const handleOnTapListItem = (item: IListViewData) => {
    navigate(`/biller/${item.id}`);
  };

  const isMaintenanceMode =
    state.error &&
    state.error.response.data.message.title === 'SERVICE_UNDER_MAINTENANCE';

  return (
    <div className="services-page safe-area">
      {isMaintenanceMode
        ? renderError(state.error, formatMessage)
        : // eslint-disable-next-line
        renderContent({ handleOnClearSearch, handleSearchChange, handleOnTapListItem, formatMessage, state })}
    </div>
  );
};

export default Services;

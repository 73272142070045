import React, { FC } from 'react';
import JsBridge from '../../utils/JsBridge';
import Button from '../Button/Button.comp';
import ERROR_DEFAULT from '../../assets/images/maintenance.png';
import packageInfo from '../../../package.json';
import './ErrorGeneral.scss';

export interface IErrorGeneralProps {
  title?: string;
  message?: string;
  image?: string;
  isError: boolean;
  buttonText?: string;
  onAction?: () => void;
}

export const ErrorGeneralDefaultProps: IErrorGeneralProps = {
  title: 'Something Wrong!',
  message: 'Opps, failure open apps or your session has expired.',
  image: ERROR_DEFAULT,
  isError: false,
  buttonText: 'BACK',
};

export const ErrorGeneralNamespace: string = 'ErrorGeneral';

const ErrorGeneral: FC<IErrorGeneralProps> = (props) => {
  const { title, message, image, isError, buttonText, onAction } = props;
  const handleButtonClick = () => {
    if (onAction) {
      onAction();
    } else {
      JsBridge.close();
    }
  };

  return (
    <div className={`error-general ${isError ? 'on' : ''}`}>
      <div className="error-container">
        <img src={image} alt="errorPage" />
        <h2 className="error-title">{title}</h2>
        <p className="error-desc">{message}</p>
      </div>
      <div className="error-footer">
        <div className="version-info">v{packageInfo.version}</div>
        <div className="error-action">
          <Button classStyle="primary full bold" onClick={handleButtonClick}>
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

ErrorGeneral.displayName = ErrorGeneralNamespace;
ErrorGeneral.defaultProps = ErrorGeneralDefaultProps;

export default React.memo(ErrorGeneral);

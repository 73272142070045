export class JsBridge {
  static INSTANCE: JsBridge;

  static getInstance = () => {
    if (!JsBridge.INSTANCE) JsBridge.INSTANCE = new JsBridge();
    return JsBridge.INSTANCE;
  };

  call: (name: string, opt?: string | object, cb?: () => void) => void;

  constructor() {
    this.call = (name: string, opt?: string | object, cb?: () => void) => {
      if (
        (window as any).AlipayJSBridge &&
        (window as any).AlipayJSBridge.call
      ) {
        (window as any).AlipayJSBridge.call(name, opt, cb);
      }
    };
  }

  on = (event: any, fn: any) => {
    document.addEventListener(event, fn, false);
  };

  off = (event: any, fn: any) => {
    document.removeEventListener(event, fn, false);
  };

  setTitle = (titleArgs: string = '') => {
    const title = titleArgs.trim();

    if ((window as any).IS_IN_SKYWALKER) {
      this.call('setTitle', {
        title,
      });
    } else {
      document.title = title;
    }
  };

  setOptionMenu = (opt: string) => {
    this.call('setOptionMenu', opt);
  };

  hideOptionMenu = () => {
    this.call('hideOptionMenu');
  };

  showOptionMenu = () => {
    this.call('showOptionMenu');
  };

  setDefaultBack = () => {
    this.call('setCloseButton', { action: 'hide' });
    this.call('setBackButton', { action: 'show' });
  };

  close = () => {
    this.call('closeSubApp', { event: 'top' });
  };
}

export default JsBridge.getInstance();

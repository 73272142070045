import { APP_CONTEXT } from '../constants';
import DEFAULT_ERROR from '../assets/images/maintenance.png';
import { IContext } from './AppProvider';
import { BillerListView, IRServiceDetail } from '../interfaces/IResponses';

export interface IErrorState {
  isError: boolean;
  image?: string;
  title?: string;
  message?: string;
  onAction?: () => void;
}

export interface IAppContext {
  isLoading: boolean;
  errorState: IErrorState;
  billerList: BillerListView[];
  serviceDetail: IRServiceDetail;
  billerNotice: {
    message: {
      en: string;
      id: string;
    };
    title: {
      en: string;
      id: string;
    };
  };
}

// INITIAL STATE
export const initialState: IContext = {
  isLoading: false,
  errorState: {
    isError: false,
    image: DEFAULT_ERROR,
    title: 'Something Wrong!',
    message: 'Opps, Failure Open Apps',
  },
  serviceDetail: {
    id: '',
    public_id: '',
    name: '',
    category: '',
    page_url: '',
    icon_abs_url: '',
    banner_abs_url: [],
    default_form: {
      form_id: '',
      name: '',
    },
    transaction_fee: '',
    dana_merchant_division_id: '',
    should_mask_beneficiary_full_name: false,
    universal_keyword: '',
    is_active: false,
    deactivation_remarks: {
      en: {
        body: '',
        title: '',
      },
      id: {
        body: '',
        title: '',
      },
    },
    created_at: '',
    updated_at: '',
  },
  billerList: [
    {
      biller_group_id: '',
      biller_group_name: '',
      created_at: '',
      icon_abs_url: '',
      id: '',
      name: '',
      pground_status: 0,
      public_id: '',
      skyw_status: 0,
      updated_at: '',
    },
  ],
  billerNotice: {
    message: {
      en: '',
      id: '',
    },
    title: {
      en: '',
      id: '',
    },
  },
  // FUNCTION OBJ DEFAULT
  setContext: () => {},
  setLoading: () => {},
  setError: () => {},
};

export const makeInitialState = () => {
  const getStorageContext = sessionStorage.getItem(APP_CONTEXT);
  if (getStorageContext) {
    return {
      ...JSON.parse(getStorageContext),
      setContext: () => {},
      setLoading: () => {},
      setError: () => {},
    };
  }
  return initialState;
};

// TYPE ACTION
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_BILLER_LIST = 'SET_BILLER_LIST';
export const SET_SERVICE_DETAIL = 'SET_SERVICE_DETAIL';
export const SET_BILLER_NOTICE = 'SET_BILLER_NOTICE';

// REDUCERS
export default (state: IAppContext, action: any) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        errorState: action.payload,
      };
    case SET_BILLER_LIST:
      return {
        ...state,
        billerList: action.payload,
      };
    case SET_BILLER_NOTICE:
      return {
        ...state,
        billerNotice: action.payload,
      };
    case SET_SERVICE_DETAIL:
      return {
        ...state,
        serviceDetail: action.payload,
      };
    default:
      return state;
  }
};

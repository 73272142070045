import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../store/AppProvider';
import ApiService from '../../services/ApiService';
import useApi from '../../hooks/useApi';
import BottomSheet from '../../components/BottomSheet/BottomSheet.comp';
import Button from '../../components/Button/Button.comp';
import REACT_LOGO from '../../assets/images/react.svg';
import NO_BILLER from '../../assets/images/no-biller.png';
import './Home.scss';

export interface IProps {}

// Create API Request
const { anteikuClient } = ApiService;

const Home: FC<IProps> = () => {
  const [openBottomSheet, setBottomSheet] = useState<boolean>(false);
  const auth = useApi(anteikuClient);
  useEffect(() => {
    // TODO: FOR TESTING PURPOSE
  }, []);

  const context = useContext(AppContext);

  const { t: formatMessage } = useTranslation();

  const closeBottomSheet = () => {
    setBottomSheet(false);
    context.setError(true);
  };

  const bottomSheetContent = () => (
    <div className="bottom-sheet-general">
      <img className="notifier-img" src={NO_BILLER} alt="No Biller" />
      <h2>{formatMessage('notifier.noBill.title')}</h2>
      <p>{formatMessage('notifier.noBill.desc')}</p>
      <Button onClick={closeBottomSheet} classStyle="primary full bold">
        {formatMessage('notifier.button')}
      </Button>
    </div>
  );

  return (
    <div className="home-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <img src={REACT_LOGO} className="rotate" alt="react-logo" />
            <h1>HOME</h1>
            <p>{formatMessage('home.title')}</p>
            <p>
              Project Template with the latest react hook, simple clean and no
              vulnerability package
            </p>
            <small>React Template by Thomi Jasir</small>
            {auth.loading ? 'Loading...' : 'Fetch'}
            {auth.error.message}
          </div>
        </div>
      </div>
      <BottomSheet isOpen={openBottomSheet}>{bottomSheetContent()}</BottomSheet>
    </div>
  );
};

export default Home;

import React, {
  FC,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import lGet from 'lodash/get';
import DEFAULT_BANNER from '../../assets/images/biller-banner-default.png';
import Button from '../../components/Button/Button.comp';
import StickyNote from '../../components/StickyNote/StickyNote.comp';
import { AppContext } from '../../store/AppProvider';
import { BillerListView } from '../../interfaces/IResponses';
import BottomSheet from '../../components/BottomSheet/BottomSheet.comp';
import HELPER from '../../utils/Helper';
import JsBridge from '../../utils/JsBridge';
import BillerBottomSheetContent from './Biller.BottomSheet.content';
import BottomSheetConfirmation, {
  ListItemConfirmation,
} from '../../components/BottomSheetConfirmation/BottomSheetConfirmation.comp';
import {
  IParamsCheckout,
  IResponsesCheckout,
} from '../../interfaces/ICheckout';
import TextInput, {
  ValidationTextInput,
} from '../../components/TextInput/TextInput.comp';
import SelectInput, {
  SelectList as SelectListProps,
  ValidationSelectInput,
} from '../../components/SelectInput/SelectInput.comp';
import './Biller.scss';
import useApiAnteiku from '../../hooks/useApiAnteiku';

export interface IProps {}

const validationTextInput: ValidationTextInput[] = [
  {
    required: true,
    message: 'Is Required',
  },
  {
    minLength: 9,
    message: 'Minimum 9 Digit',
  },
];

const validationYearInput: ValidationTextInput[] = [
  {
    required: true,
    message: 'Is Required',
  },
  {
    minLength: 4,
    message: 'Minimum 4 Digit',
  },
];

const validationSelectInput: ValidationSelectInput[] = [
  {
    required: true,
    message: 'Is Required',
  },
];

export interface IStateBiller {
  salesOrderId: string;
  bottomSheet: {
    isOpen: boolean;
    loadingState: boolean;
    content: string;
  };
  bottomSheetConfirm: {
    isOpen: boolean;
    name: string;
    data: {
      title: string;
      desc: string;
      listItem: ListItemConfirmation[];
    };
  };
}

const Biller: FC<IProps> = () => {
  const { billerGroupId } = useParams();
  const formHook = useForm({ mode: 'all', shouldFocusError: false });
  const context = useContext(AppContext);
  const apiClient = useApiAnteiku();
  const navigate = useNavigate();
  const { t: formatMessage } = useTranslation();
  const [state, setState] = useState<IStateBiller>({
    salesOrderId: '',
    bottomSheet: {
      isOpen: false,
      loadingState: false,
      content: 'DEFAULT',
    },
    bottomSheetConfirm: {
      isOpen: false,
      name: '',
      data: {
        title: '',
        desc: '',
        listItem: [],
      },
    },
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = formHook;

  useLayoutEffect(() => {
    context.setLoading(false);
  }, []);

  const mapToSelectList = (data: BillerListView[]) =>
    data.map(
      (item: BillerListView) =>
        ({
          id: item.id,
          name: item.name,
        } as SelectListProps),
    );

  const memoizeFormHook = useMemo(() => formHook, [formHook]);

  const selectListBiller = useMemo(
    () =>
      mapToSelectList(
        context.billerList.filter(
          (item: BillerListView) => item.biller_group_id === billerGroupId,
        ),
      ),
    [context.billerList],
  );

  const requestPay = (salesOrderId: string) => {
    const { requestPay: pay } = apiClient;
    pay(salesOrderId)
      .then((result: any) => {
        JsBridge.call('callSubApp', {
          url: result.dana_cashier_url,
        });
        setState((prevState) => ({
          ...prevState,
          bottomSheet: {
            isOpen: false,
            loadingState: true,
            content: 'DEFAULT',
          },
        }));
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          bottomSheet: {
            isOpen: true,
            loadingState: false,
            content: 'ERROR',
          },
        }));
      });
  };

  const createCheckout = (params: IParamsCheckout) => {
    const { createCheckout: initCheckout } = apiClient;
    setState((prevState) => ({
      ...prevState,
      bottomSheet: {
        isOpen: true,
        loadingState: true,
        content: 'DEFAULT',
      },
    }));
    initCheckout(params)
      .then((result: { checkout: IResponsesCheckout; label: any }) => {
        const { checkout, label } = result;
        const billerData = [
          ...Object.entries(checkout.bill_data)
            .filter((item) => item[1] !== '')
            .map((item: any) => {
              const [keyObj, valueObj] = item;
              return {
                id: keyObj,
                itemCss: '',
                valueCss: '',
                nameCss: '',
                name: lGet(
                  label,
                  `${keyObj}.${HELPER.getLang()}`,
                  `UNKNOWN-${keyObj}`,
                ),
                value: valueObj,
              };
            }),
          {
            id: 'trx_amount',
            itemCss: '',
            valueCss: '',
            nameCss: '',
            name: formatMessage('panel.transaction.amount'),
            value: HELPER.formatRupiah(checkout.transaction_amount),
          },
          {
            id: 'admin_fee',
            itemCss: 'double-devider',
            valueCss: '',
            nameCss: '',
            name: formatMessage('panel.admin.fee'),
            value: HELPER.formatRupiah(checkout.admin_fee),
          },
          {
            id: 'total_amount',
            itemCss: '',
            valueCss: 'bold',
            nameCss: '',
            name: formatMessage('panel.total.amount'),
            value: HELPER.formatRupiah(checkout.total_amount),
          },
        ];
        const getBillerSelect = context.billerList.find(
          (x: BillerListView) => x.id === params.biller_id,
        );
        setState((prevState) => ({
          ...prevState,
          salesOrderId: checkout.sales_order_id,
          bottomSheet: {
            isOpen: false,
            loadingState: true,
            content: 'DEFAULT',
          },
          bottomSheetConfirm: {
            isOpen: true,
            name: 'DETAIL PESANAN',
            data: {
              title: checkout.beneficiary_account_number,
              desc: `${context.serviceDetail.name} - ${getBillerSelect?.name}`,
              listItem: billerData,
            },
          },
        }));
      })
      .catch((error: any) => {
        const errorContent = lGet(
          error,
          'response.data.message.title',
          'ERROR',
        );
        setState((prevState) => ({
          ...prevState,
          bottomSheet: {
            isOpen: true,
            loadingState: false,
            content: errorContent,
          },
        }));
      });
  };

  const closeBottomSheet = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      bottomSheet: {
        isOpen: false,
        loadingState: false,
        content: 'DEFAULT',
      },
    }));
  }, []);

  const renderNote = useMemo(() => {
    if (context.billerNotice) {
      const { billerNotice } = context;
      const titleName = billerNotice.title[HELPER.getLang()] || '';
      const messageContent = billerNotice.message[HELPER.getLang()] || '';
      return (
        <StickyNote title={titleName}>
          <div dangerouslySetInnerHTML={{ __html: messageContent }}></div>
        </StickyNote>
      );
    }
    return null;
  }, []);

  const renderBottomSheet = useMemo(
    () => (
      <BottomSheet
        loadingState={state.bottomSheet.loadingState}
        isOpen={state.bottomSheet.isOpen}
        onClose={closeBottomSheet}
        onSwipe={closeBottomSheet}
      >
        {BillerBottomSheetContent({
          typeContent: state.bottomSheet.content,
          onClose: closeBottomSheet,
          t: formatMessage,
        })}
      </BottomSheet>
    ),
    [state.bottomSheet],
  );

  const handleOnConfirmBottomSheet = () => {
    setState((prevState) => ({
      ...prevState,
      bottomSheet: {
        isOpen: true,
        loadingState: true,
        content: 'DEFAULT',
      },
      bottomSheetConfirm: {
        ...prevState.bottomSheetConfirm,
        isOpen: false,
        name: 'DETAIL PESANAN',
      },
    }));
    requestPay(state.salesOrderId);
  };

  const handleOnCloseBottomSheetConfirm = () => {
    setState((prevState) => ({
      ...prevState,
      bottomSheetConfirm: {
        ...prevState.bottomSheetConfirm,
        isOpen: false,
        name: 'DETAIL PESANAN',
      },
    }));
  };

  const onSubmit = useCallback((data: any) => {
    const isPBB = context.serviceDetail.name.includes('PBB');
    createCheckout({
      biller_id: data.cityAreaId,
      customer_number: isPBB
        ? `${data.noBiller}${data.billerYear}`
        : data.noBiller,
    });
  }, []);

  const renderYearField = useMemo(() => {
    const isPBB = context.serviceDetail.name.includes('PBB');

    return (
      isPBB && (
        <>
          <br />
          <div className="form-group">
            <TextInput
              name="billerYear"
              title={formatMessage('biller.form.billerYear')}
              placeholder={formatMessage('biller.form.billerYear.placeholder')}
              type="number"
              formHook={memoizeFormHook}
              validation={validationYearInput}
            />
          </div>
        </>
      )
    );
  }, [context.serviceDetail]);

  const renderTextInput = useMemo(() => {
    if (context.serviceDetail.name.includes('PBB')) {
      return {
        title: formatMessage('biller.form.billNumberPBB'),
        placeholder: formatMessage('biller.form.billNumberPBB.placeholder'),
      };
    }
    return {
      title: formatMessage('biller.form.billNumber'),
      placeholder: formatMessage('biller.form.billNumber.placeholder'),
    };
  }, [context.serviceDetail]);

  return (
    <div className="biller-page">
      <div className="biller-banner">
        <img src={DEFAULT_BANNER} alt="Biller Banner" />
      </div>
      <div className="biller-content">
        <div className="biller-form">
          <div className="form-group">
            <SelectInput
              name="cityArea"
              selectList={selectListBiller}
              title={formatMessage('biller.form.city')}
              placeholder={formatMessage('biller.form.city.placeholder')}
              formHook={memoizeFormHook}
              validation={validationSelectInput}
            />
          </div>
          {renderYearField}
          <br />
          <div className="form-group">
            <TextInput
              name="noBiller"
              title={renderTextInput.title}
              placeholder={renderTextInput.placeholder}
              type="number"
              formHook={memoizeFormHook}
              validation={validationTextInput}
            />
          </div>

          <div className="form-btn">
            <Button
              onClick={handleSubmit(onSubmit)}
              classStyle={`primary full bold ${isValid ? '' : 'inactive'}`}
            >
              {formatMessage('biller.btn.check')}
            </Button>
          </div>
        </div>
        {renderNote}
      </div>
      {renderBottomSheet}
      <BottomSheetConfirmation
        {...state.bottomSheetConfirm}
        onConfirm={handleOnConfirmBottomSheet}
        onClose={handleOnCloseBottomSheetConfirm}
        navigate={navigate}
      />
    </div>
  );
};

export default Biller;
